<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">领取记录</div>
		<div class="form-search-box mt20">
			<a-form layout="inline">
				
				<a-form-item label="会员手机号">
					<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
				</a-form-item>
				<a-form-item label="项目名称">
					<a-input v-model="search.product" placeholder="请输入项目名称"></a-input>
				</a-form-item>
				<a-form-item label="操作员">
					<a-input v-model="search.keyword" placeholder="请输入操作员"></a-input>
				</a-form-item>
				<a-form-item label="来源应用">
					<div>
						<a-select style="width: 120px" placeholder="请选择" v-model="search.app_id">
							<a-select-option :value="-99">请选择</a-select-option>
							<a-select-option :value="0">基础会员卡应用</a-select-option>
						</a-select>
					</div>
				</a-form-item>
				<a-form-item label="领取时间">
					<a-range-picker v-model="search.time" format="YYYY-MM-DD" />
				</a-form-item>
				<a-form-item>
					<a-button @click="searchAct" type="primary">查询</a-button>
					<a-button @click="cancelAct" class="ml10">取消</a-button>
				</a-form-item>
			</a-form>
		</div>
		
		
		<div class="mt20">
			<div class="wxb-table-white">
				<a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"	:loading="loading">
				</a-table>
			</div>
		</div>
			
			
		
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data(){
			return {
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					mobile: "",
					product:"",
					keyword:"",
					app_id: -99,
					time:['',''],
				},
				columns: [
					{title: 'ID',dataIndex: 'id',align: 'center'},
					{title: '会员',key: 'mobile',align: 'center',scopedSlots: {customRender: 'mobile'}},
					{title: '项目名称',dataIndex: 'product',align: 'center',ellipsis: true},
					{title: '实际金额',dataIndex: 'balance',align: 'center',ellipsis: true},
					{title: '来源小程序',dataIndex: 'app_name',align: 'center',ellipsis: true},
					{title: '操作员',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '时间',dataIndex: 'add_time',align: 'center',ellipsis: true},
				],
				datas:[
					
				],
			}
		},
		methods:{
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				console.log(filters);
				console.log(sorter);
				this.getLists();
			},
			searchCancel() { 
				this.search = {
					mobile: "",
					product: "",
					keyword:"",
					time:['',''],
					app_id: -99,
				};
			},
		}
	}
</script>

<style>
	
</style>